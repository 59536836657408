@import "../../css/general_styles.scss";

.ant-layout-header {
  line-height: auto !important;
  padding: 1em !important;
  height: 70px;
}
.separatorLanguaje{
padding-top: 0.5rem;
}
.ant-layout-header {
  width: 100%;
  background: transparent;
  display: flex;
  padding: 0;
  align-items: center;
  justify-content: space-between;
  .logo {
    width: 20%;
    padding: 0 2em;
    img {
      height: 50px;
    }
  }
  .Nav {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .Container {
      .ContactBtn {
        background-color: $primary-color;
        border-radius: 6px;
        padding: 1em 2em;
        color: white;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        @include primary-text(12px, white, 400);
        text-transform: uppercase;
      }
      .ContactBtn:hover {
        background-color: $primary-color-dark;
        transition: 1s;
      }
    }
    .hoverContainer:hover {
      .dotHover {
        height: 5px;
        width: 5px;
        border-radius: 2em;
        background-color: $primary-color;
        justify-content: center;
        align-items: center;
        display: flex;
      }
    }
    .hoverContainer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .dotHover {
        height: 5px;
        width: 5px;
        border-radius: 2em;
        background-color: white;
        justify-content: center;
        align-items: center;
        display: flex;
      }
      a {
        height: 35px;
        display: flex;
        @include primary-text(12px, $black, 400);
        letter-spacing: 0.2em;
        text-transform: uppercase;
        align-items: center;
        justify-content: center;
        padding: 0 2em;
        margin: 0;
      }
    }
  }
}

.NavResponsive {
  display: none;
}