@import "../../css/general_styles.scss";

.container {
  span{
    position: absolute;
    bottom: 2rem;
    right: 2rem;
    @include primary-text(2rem, white, 400);
  }
  height: 100%;
  img {
    width: 100%;
  }
}

.ant-carousel-vertical .slick-dots-bottom {
  right: 5em !important;
}

.ant-carousel-vertical .slick-dots li.slick-active button {
  width: 10px !important;
  height: 10px !important;
  border-radius: 50px;
  background: $primary-color !important;
}

.ant-carousel-vertical .slick-dots li button {
  width: 10px !important;
  height: 10px !important;
  border-radius: 50px !important;
  opacity: 1;
}
