@import "../../css/general_styles.scss";

.hamburgerMenu {
  button {
    border: none;
    padding: 0;
    margin: 0;
  }
  .hamburger {
    height: 20px;
  }
}

.ant-popover {
  width: 100% !important;
  right: 0 !important;
  left: 0 !important;
  z-index: 100;
}

.ant-popover-inner-content {
  padding: 0;
  a {
    height: 35px;
    display: flex;
    @include primary-text($label, $black, 400);
    letter-spacing: 0.2em;
    text-transform: uppercase;
    align-items: center;
    justify-content: center;
  }
  a:hover {
    color: $primary-color;
    background: rgb(255, 223, 223);
  }
}

@media only screen and (max-width: 768px) {
  .Nav {
    display: none !important;
  }
  .NavResponsive {
    display: flex !important;
    width: 80%;
    justify-content: flex-end;
    align-items: center;
  }
}