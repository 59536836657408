@import "../../css/general_styles.scss";

.videoGallery {
  display: flex;
  flex-wrap: wrap;
}
.overlayText{
  span{
    padding: .2em;
  @include primary-text(200px, white, normal);
  font-family: "Octin", sans-serif;
  opacity: 0.8;
  position: absolute;
  z-index: 500;
  line-height: 1;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
}

.ant-modal-footer {
  display: none;
}

.ant-modal-body {
  padding: 0;
  img {
    width: 100%;
    height: 100%;
  }
}
.Img6{
  text-align: center;
  margin: auto;
  position: relative;
  width: 33.33%;
  height: 50vh;
  display: flex;
  justify-content: center;
  background: #d71d2e;
  align-items: center;
  span{
    font-size: 5rem;
    font-weight: normal;
    color: rgb(255, 255, 255);
    font-family: "Octin", sans-serif;
    opacity: 0.5;
    line-height: normal;
  }
}

@media only screen and (max-width: 900px) {
  .videoGallery {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    .img, .Img6 {
      width: 50%;
    } 
  }
}

@media only screen and (max-width: 600px) {
  .videoGallery {
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    .img, .Img6 {
      width: 100%;
    } 
  }
}