@import "../../css/general_styles.scss";

.GreySection {
  background-image: url("../../img/greybg.png");
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 0 0 10em;
  .column {
    width: 50%;
    h1 {
      @include primary-text($header, $black, normal);
      font-family: "Octin", sans-serif;
      opacity: 0.5;
      line-height: 0;
      margin-bottom: 4rem;
    }
    .subtitle {
      @include primary-text(30px, $black, bold);
      text-transform: uppercase;
      letter-spacing: 0.2em;
      margin-top: .3em;
    }
    p {
      @include primary-text(24px, $grey, 400);
      letter-spacing: 0.2em;
      margin-top: 1em;
    }
  }
}


@media only screen and (max-width: 800px) {
  .GreySection {
    padding: 4rem;
    .column {
      width: 100%;
    }
  }
}

@media only screen and (max-width: 400px) {
  .GreySection {
    .column {
      h1 {
        font-size: 5em;
      }
      .subtitle {
        margin-top: 0.3em;
      }
    }
  }
}