@import "../css/general_styles.scss";

.ant-btn-primary {
  background: $primary-color;
  border: none;
  padding: 1em 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  @include primary-text($body, white, 400);
  text-transform: uppercase;
  letter-spacing: 0.2em;
}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
  color: #fff;
  background: #ac131f;
  border-color: transparent;
}

.ant-layout {
  background: transparent;
  .ant-layout-content {
    padding: 0 !important;
    height: auto;
  }
}

/*.ant-modal{
  width: 100%!important;
}*/
.footer {
  padding: 0 4em 2em 4em;
  background: $black;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
 .footerLogoHeight{
   height: 6rem;
 }
  .top {
    padding: 3em 0 2em 0;
    display: flex;
    justify-content: space-between;
    .footerLogo {
      align-items: center;
      width: 20%;
      display: flex;
      .map {
        margin-left: 30px;
        display: flex;
        flex-direction: row;
        align-items: center;
        text-align: center;
        a {
          display: block;
          @include primary-text($body, $white, normal);
          text-transform: uppercase;
          letter-spacing: 0.2;
          margin: 0 1em;
          width: 4rem;
        }
        a:hover {
          opacity: 0.5;
          transition: 1s;
        }
      }
    }
    .contact {
      width: 20%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: flex-end;
      .ant-btn-secondary-button {
        width: 175px;
        justify-content: center;
        border: none;
        background: $green;
        display: flex;
        align-items: center;
        @include primary-text($body, $white, normal);
        text-transform: uppercase;
        letter-spacing: 0.2;
        img {
          margin-right: 0.5em;
        }
      }
      p {
        @include primary-text($body, $white, normal);
        text-transform: uppercase;
        letter-spacing: 0.2;
        margin-bottom: 1em;
      }
    }
    .question {
      width: 60%;
      p {
        @include primary-text($body, $white, normal);
        text-transform: uppercase;
        letter-spacing: 0.2;
        margin-bottom: 2em;
      }
    }
  }
  .bottom {
    padding-top: 1em;
    border-top: 1px solid $white;
    display: flex;
    text-transform: uppercase;
    @include primary-text($body, $white, normal);
    letter-spacing: 0.2em;
    opacity: 0.5;
  }
}

.wpp{
  width: 3rem;
  cursor: pointer;
  margin-right: .5rem;
}
@media screen and (max-width: 992px) {
  .footer .top {
    flex-wrap: wrap;
    .footerLogo {
      width: 50%;
    }
    .contact {
      width: 50%;
    }
    .question {
      width: 100%;
      margin: 1em 0;
    }
  }
}

@media screen and (max-width: 500px) {
  .footer {
    .top {
      .map{
        display: none!important;
      }
      .footerLogo {
        justify-content: center;
        width: 100%;
        margin-bottom: 1rem;
      }
      .contact {
        justify-content: center;
        width: 100%;
        margin: 1em 0;
        display: flex;
        align-items: center;
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .footer {
    .top {
      .map{
        flex-direction: column!important;
      }
    }
  }
}
