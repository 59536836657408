body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }
}


@font-face {
  font-family: "Octin";
  src: url("../src/fuentes/octin_spraypaint_a_rg-webfont.woff");
}