@import "../../css/general_styles.scss";

.videoSection {
  position: relative;
  animation: fadeIn 5s;
  -webkit-animation: fadeIn 5s;
  -moz-animation: fadeIn 5s;
  -o-animation: fadeIn 5s;
  -ms-animation: fadeIn 5s;

  .video {
    width: 100%;
    margin: 0;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.logoContainerVideo{
  position: absolute;
  width: 100%;
  height: 99%;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAAXNSR0IArs4c6QAAABtJREFUGFdjZGBg+M/AwMDIAAVwBjYBsEoMFQBW5gIF0HSEnwAAAABJRU5ErkJggg==) repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 0;
}
.overlay{
  background-color: rgb(0 0 0 / 81%);;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}
.imgLogoVideo{
  height: 23rem;
  z-index: 1;
}
.textContainer {
  position: absolute;
  width: 100%;
  height: 90%;
  .floating1,
  .floating2 {
    position: absolute;
    @include primary-text($label, $white, normal);
    text-transform: uppercase;
    right: 2em;
  }
  .floating1 {
    top: 2em;
    text-align: right;
    right: 2em;
  }
  .floatingLogo{
    position: absolute;
    top: 50%;
  }
  .floating2 {
    bottom: 0em;
    right: 2em;
    p {
      writing-mode: vertical-rl;
      text-orientation: mixed;
    }
  }
}

@media only screen and (max-width: 800px) {
  .imgLogoVideo{
    height: 10rem;
  }
}