@font-face {
  font-family: 'Kanit';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/kanit/v7/nKKS-Go6G5tXcraQI7mlVaQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Kanit';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/kanit/v7/nKKX-Go6G5tXcr72GwU.ttf) format('truetype');
}
@font-face {
  font-family: 'Kanit';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/kanit/v7/nKKZ-Go6G5tXcoaS.ttf) format('truetype');
}
@font-face {
  font-family: 'Kanit';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/kanit/v7/nKKU-Go6G5tXcr5mOCWg.ttf) format('truetype');
}
