@import "../../css/general_styles";

.imgPhoto {
  .faicon{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    font-size: 5rem;
    color: white;
    bottom: 0;
    right: 0;
    margin: auto;
    opacity: 0.7;
  }
  position: relative;
  width: 25%;
  height: 100vh;
  .text {
    display: none;
  }
  .Photo1 {
    background: transparent;
    border: none;
    width: 100%;
    height: 100%;
    background-image: url("../../img/miniatura jjpp.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;
  }
}

.imgPhoto:hover {
  .text {
    flex-direction: column;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 50;
    background: rgba(182, 0, 0, 0.60);
    height: 100%;
    width: 100%;
    padding: 1.5em;
    text-align: center;
    @include primary-text(2em, white, 400);
    text-transform: uppercase;
    line-height: 1em;
    letter-spacing: 0.2em;
  }
  .buttonImg {
    z-index: -10;
    opacity: 0.5;
  }
}

.videoModal {
  width: 100%;
}

.ant-modal-header {
  display: none;
}

.ant-modal-content {
  background: transparent;
}

.anticon-close {
  color: white;
}
