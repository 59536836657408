@import "../../css/general_styles.scss";

.redSection {
  background-image: url("../../img/redbg.png");
  height: 100%;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 8em;
  display: flex;
  align-items: center;
  margin-top: -0.4em;
  h1 {
    @include primary-text(95px, $white, normal);
    font-family: "Octin", sans-serif;
    opacity: 0.5;
    line-height: normal;
    line-break: auto;
  }
  .subtitle {
    @include primary-text(30px, $white, bold);
    text-transform: uppercase;
    letter-spacing: 0.2em;
    margin-top: 2em;

  }
  p {
    @include primary-text(24px, $white, normal);
    letter-spacing: 0.2em;
  }
}

.GreySection:hover {
  .moveUp {
    animation: moveUp 1s;
    -webkit-animation: moveUp 1s;
  }
  .moveUp2 {
    animation: moveUp2 2s;
    -webkit-animation: moveUp2 2s;
  }
  .fadeIn2 {
    animation: fadeIn 4s;
    -webkit-animation: fadeIn 4s;
    -moz-animation: fadeIn 4s;
    -o-animation: fadeIn 4s;
    -ms-animation: fadeIn 4s;
  }
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  @-moz-keyframes fadeIn2 {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  @-webkit-keyframes fadeIn2 {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  @-o-keyframes fadeIn2 {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  @-ms-keyframes fadeIn2 {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

}

@keyframes moveUp {
  from {
    margin-top: -200px;
    //width: 300%;
  }

  to {
    margin-top: 0px;
    //width: 100%;
  }
}

@-webkit-keyframes moveUp{
  from {
    margin-top: -200px;
    //width: 300%;
  }

  to {
    margin-top: 0px;
    //width: 100%;
  }
}

@keyframes moveUp2 {
  from {
    margin-top: -200px;
    //width: 300%;
  }

  to {
    margin-top: 0px;
    //width: 100%;
  }
}

@-webkit-keyframes moveUp2{
  from {
    margin-top: -200px;
    //width: 300%;
  }

  to {
    margin-top: 0px;
    //width: 100%;
  }
}
.redSection:hover {
  .move {
    animation: move 2s;
    -webkit-animation: move 2s;
  }
  .move2 {
    animation: move 3s;
    -webkit-animation: move 3s;
  }
  .move3 {
    animation: move 4s;
    -webkit-animation: move 4s;
  }
}

@keyframes move {
  from {
    margin-left: -100%;
    //width: 300%;
  }

  to {
    margin-left: 0%;
    //width: 100%;
  }
}

@-webkit-keyframes move, move2, move3 {
  from {
    margin-left: -100%;
    //width: 300%;
  }

  to {
    margin-left: 0%;
    //width: 100%;
  }
}



.fadeIn {
  animation: fadeIn 3s;
  -webkit-animation: fadeIn 3s;
  -moz-animation: fadeIn 3s;
  -o-animation: fadeIn 3s;
  -ms-animation: fadeIn 3s;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.logoRed{
  img{
    width: 20rem;
    margin-top: 3rem;
  }
    
}

@media only screen and (max-width: 425px) {
  .GreySection {
    height: 148vh!important;
    padding: 2em!important;
  }
  .redSection {
    height: 134vh!important;
    padding: 2em!important;
    h1{
      font-size: 4rem;
    }
  }   
}
@media only screen and (max-width: 600px) {
  .GreySection {
    .column{
      h1{
        font-size: 4rem!important;
      }
    }
  }
  .redSection {
    h1{
      font-size: 4rem;
    }
  }
}
@media only screen and (max-width: 800px) {
  .redSection {
    padding: 4rem;
}

}
