// Colors

$primary-color: #BE1522;
$primary-color-dark: #940d18;
$secondary-color: #333;
$green: #30CC47;

$black: #333;
$white: #fff;
$grey: #565656;
$dark-grey: #788290;
$medium-grey: #c5d0de;
$light-grey: #f3f6f8;

// Typographic Scale
$title: 28px;
$subtitle: 18px;
$header: 112px;
$h1: 18px;
$h2: 16px;
$h3: 14px;
$button: 12px;
$body: 12px;
$tabs: 10px;
$label: 10px;

// Shadow
$shadow: 0 1px 6px rgba(147, 141, 158, 0.16), 0 2px 6px rgba(52, 53, 62, 0.16);
$shadow-header: 0 1px 1px 0 rgba(0, 0, 0, 0.05);
$shadow-blue: 0 1px 6px rgba(1, 108, 165, 0.16), 0 2px 6px rgba(1, 108, 165, 0.16);

// Fonts

@mixin primary-text($font-size, $font-color, $font-weight) {
  margin: 0;
  font-family: 'Kanit', sans-serif;
  font-size: $font-size;
  font-weight: $font-weight;
  @if $font-color {
    color: $font-color;
  }
}
/*
@mixin secondary-text($font-size, $font-color, $font-weight) {
  margin: 0;
  font-family: 'Octin', sans-serif;
  font-size: $font-size;
  font-weight: $font-weight;
  @if $font-color {
    color: $font-color;
  }
}
*/

// Container

@mixin container() {
  background: #fff;
  box-shadow: 0 1px 6px rgba(147, 141, 158, 0.16), 0 2px 6px rgba(52, 53, 62, 0.28);
  border-radius: 6px;
  padding: 1em;
}
