@import "../../css/general_styles.scss";

.photoGallery {
  display: flex;
  height: 100vh;
  img{
    width: 25%!important;
  }
}

@media only screen and (max-width: 768px) {
  .imgPhoto{
    height: 30vh;
    width: 100%;
  }
  .photoGallery {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    .img{
      width: 50%;
    } 
  }
}