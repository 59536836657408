@import "../../../css/general_styles";

.img {
  .faicon{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    font-size: 5rem;
    color: white;
    bottom: 0;
    right: 0;
    margin: auto;
    opacity: 0.7;
  }
  position: relative;
  width: 33.33%;
  height: 50vh;
  .text {
    display: none;
  }
  .Img2 {
    background: transparent;
    border: none;
    width: 100%;
    height: 100%;
    background-image: url("../../../img/miniaturaVideoMapping.JPG");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;
  }
}
